<template>
  <div id="section-content" class="container">
    <div class="bar-back">
      <router-link to="/member/numbersets"><i class="fas fa-chevron-left"></i> จัดการเลขชุด</router-link>
    </div>
    <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 140px) - 50px)">
      <div
        class="
          bgwhitealpha
          text-secondary
          shadow-sm
          rounded
          p-2
          px-2
          xtarget
          col-lotto
          d-flex
          flex-row
          justify-content-between
          mb-1
          pb-0
        "
      >
        <div class="lotto-title">
          <h4><i class="fas fa-file-alt"></i> รายละเอียดเลขชุด</h4>
        </div>
        <button @click="deleteSet()" data-id="1117464" class="btn btn-danger btn-sm deleteconfirm">
          <i class="fas fa-trash-alt"></i> ลบรายการนี้
        </button>
      </div>
      <div class="bgwhitealpha text-secondary shadow-sm rounded pt-2 px-2 pb-0 xtarget col-lotto">
        <div class="row px-2 align-items-center">
          <div class="col-12 col-sm-12 col-md-6">
            <label><i class="fas fa-tag"></i> ชื่อชุด</label> : {{ numberset.title }}
          </div>
          <div class="col-12 col-sm-12 col-md-6 text-left text-sm-left text-md-right">
            <label><i class="far fa-calendar"></i> สร้างเมื่อ</label> :
            {{ numberset.date_create }}
          </div>
          <div class="w-100"></div>
          <div class="col-12 table-secondary border-top border-bottom border-dark mb-1">
            <h6>ชุดตัวเลข</h6>
          </div>
          <div class="col-12">
            <ol class="pl-5 list-numsets" v-if="numberset.num_json">
              <template v-for="item in numberset.num_json.filter(e => e.list.length)">
                 <template v-for="(itemSub, indexSub) in item.list" >
                  <li class="p-1 border-bottom" :key="indexSub">
                    <div class="row text-center">
                      <div class="col-lg-4 col-md-5 col-sm-6 col-6">{{ item.name }}</div>
                      <div class="col-lg-8 col-md-7 col-sm-6 col-6">
                        {{ itemSub }}
                      </div>
                    </div>
                  </li>
                </template>
              </template>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade modal_confirm_delete" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-white">ข้อความแจ้งเตือน</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-white">คุณแน่ใจนะว่าต้องการลบ เลขชุดนี้ ?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">ปิด</button>
            <button type="button" class="btn btn-danger btnconfirmdelete">ลบ</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNumberSet, deleteNumberSet } from '@/services'
import { mapActions } from 'vuex'
export default {
  async created () {
    try {
      this.setLoading(true)
      this.id = this.$route.params.id
      const resp = await getNumberSet()
      if (resp.status_code === 'SUCCESS') {
        this.numberset = resp.data.listSetNumber.find(e => e.id === Number(this.id))
      }
      this.setLoading(false)
    } catch (error) {
      this.setLoading(false)
    }
  },
  data () {
    return {
      id: '',
      numberset: {}
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    async deleteSet () {
      try {
        this.setLoading(true)
        const resp = await deleteNumberSet(this.numberset.id)
        if (resp.status_code === 'SUCCESS') {
          const resp = await getNumberSet()
          if (resp.status_code === 'SUCCESS') {
            this.$router.replace('/member/numbersets')
          }
        }
        this.setLoading(false)
      } catch (error) {
        this.setLoading(false)
      }
    }
  }
}
</script>

<style>
</style>
